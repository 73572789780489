import React from 'react';
import PropTypes from 'prop-types';

function DefaultLayout({ title, children }) {
  return (
    <section className="hero is-fullheight is-light is-bold">
      {/*<div className="hero-head">*/}
        {/*<div className="container">*/}
          {/*<h1 className="title">*/}
            {/*Relax Italiano SRL*/}
          {/*</h1>*/}
        {/*</div>*/}
      {/*</div>*/}
      {children}
    </section>
  );
}

// function DefaultLayout({ title, children }) {
//   return (
//     <section className="hero is-primary is-medium">
//       <div className="hero-head">
//         <nav className="navbar">
//           <div className="container">
//             <div className="navbar-brand">
//               {title}
//               <span className="navbar-burger burger" data-target="navbarMenuHeroA">
//                 <span></span>
//                 <span></span>
//                 <span></span>
//               </span>
//             </div>
//           </div>
//         </nav>
//       </div>
//
//       {children}
//     </section>
//   );
// }

DefaultLayout.defaultProps = {};
DefaultLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};
export default DefaultLayout;
