import React from 'react';
import { compose } from 'recompose';

function Loader() {
  return (
    <div className="lds-roller">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

Loader.defaultProps = {};
Loader.propTypes = {};
export default compose(
)(Loader);
