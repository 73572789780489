import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout.layout';

const e = React.createElement;

/**
 *
 * @param layout
 * @param navbar
 */
const withLayout = ({ layout = DefaultLayout, title = null } = { layout: DefaultLayout }) => (WrappedComponent) => {
  const WithLayout = (props) => {
    return e(layout,
      { title },
      e(WrappedComponent, {...props})
    );
  };
  const wrappedComponentName = WrappedComponent.displayName
    || WrappedComponent.name
    || 'Component';

  WithLayout.displayName = `withLayout(${wrappedComponentName})`;
  return WithLayout;
};

export default withLayout;