import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';

function FileUploader({ onDrop }) {
  // function onDrop(acceptedFiles, rejectedFiles) {
  //    console.log(acceptedFiles, rejectedFiles);
  //  }
  return (
    <Dropzone
      onDrop={onDrop}
      accept=".xls,.xlsx"
      multiple={false}
    >
      {({getRootProps, getInputProps, isDragActive}) => {
        return (
          <div
            {...getRootProps()}
            className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
          >
            <input {...getInputProps()} />
            {
              isDragActive ?
                <>
                  <button type="button" className="button is-large is-fullwidth is-link">
                    <span className="icon is-medium">
                      <i className="fas fa-file-excel"/>
                    </span>
                    <span>Trascina qui il file</span>
                  </button>
                </>
                :
                <>
                  <button type="button" className="button is-large is-fullwidth is-link">
                    <span className="icon is-medium">
                      <i className="fas fa-file-excel"/>
                    </span>
                    <span>Carica indirizzi</span>
                  </button>
                </>
            }
          </div>
        )
      }}
    </Dropzone>
  );
}

FileUploader.defaultProps = {};
FileUploader.propTypes = {
  onDrop: PropTypes.func.isRequired,
};
export default compose(
)(FileUploader);
