import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Home from './routes/home/Home';
import history from './history';

function Routes() {
  return (
    <Router history={history}>
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </div>
    </Router>
  );
}

Routes.defaultProps = {};
Routes.propTypes = {};
export default Routes;
