import React from 'react';
import { compose } from 'recompose';
import FileSaver from 'file-saver';
import axios from 'axios';
import XLSX from 'xlsx';
import Swal from 'sweetalert2';

import withLayout from '../../hocs/withLayout.hoc';
import Loader from './components/Loader';
import FileUploader from './components/FileUploader';

const API_URL = process.env.REACT_APP_API_URL
if (!API_URL) {
    throw new Error('REACT_APP_API_URL required');
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      addresses: undefined,
      insertResult: undefined,
      insertErrors: undefined,
      excelFile: undefined,
      notValid: [],
      main_title: 'APERTURA SPECIALE SABATO E DOMENICA CON ORARIO CONTINUATO',
      gift_title: 'CORREDO LENZUOLA MATRIMONIALI O DUE GUANCIALI ANALLERGICI\n',
      gift_subtitle: 'APERTURA NEGOZIO: GIOVEDI\' ____ VENERDI\' ____ DALLE ORE 10.00 ALLE ORE 13.00 E DALLE ORE 15.30 ALLE ORE 19.30\n' +
        'SABATO ____ E DOMENICA ____ ORARIO CONTINUATO DALLE ORE 10.00 ALLE ORE 19.00',
      last_message: 'VI ASPETTIAMO DA GIOVEDI\' ____ A DOMENICA ____ 2022',
    };

    this.handleExampleClick = this.handleExampleClick.bind(this);
    this.handleInvitesClick = this.handleInvitesClick.bind(this);
    this.handleLoadClick = this.handleLoadClick.bind(this);
    this.handleChangeMainTitle = this.handleChangeMainTitle.bind(this);
    this.handleChangeGiftTitle = this.handleChangeGiftTitle.bind(this);
    this.handleInvitesClick = this.handleInvitesClick.bind(this);
    this.handleChangeGiftSubtitle = this.handleChangeGiftSubtitle.bind(this);
    this.handleChangeLastMessage = this.handleChangeLastMessage.bind(this);
    this.handleDropFile = this.handleDropFile.bind(this);
    this.setAddresses = this.setAddresses.bind(this);
  }
  handleChangeMainTitle(event) {
    event.preventDefault();
    this.setState({main_title: event.target.value});
  }
  handleChangeGiftTitle(event) {
    event.preventDefault();
    this.setState({gift_title: event.target.value});
  }
  handleChangeGiftSubtitle(event) {
    event.preventDefault();
    this.setState({gift_subtitle: event.target.value});
  }
  handleChangeLastMessage(event) {
    event.preventDefault();
    this.setState({last_message: event.target.value});
  }
  setAddresses(addr) {
    const addresses = addr.map(a => ({ ...a, telefono: ''+a.telefono, cap: ''+a.cap }));
    console.log('Received', addresses);
    this.setState({ addresses });
    const notValid = addresses.filter(address => {
      if (!address.telefono) return true;
      if (!address.nome) return true;
      if (!address.indirizzo) return true;
      if (!address.cap) return true;
      if (!address.citta) return true;
      if (!address.provincia) return true;
      if (!address.note) return true;
      return false;
    });
    this.setState({ notValid, insertResult: false });
  }
  async handleExampleClick(event) {
    event.preventDefault();
    const { main_title, gift_title, gift_subtitle, last_message } = this.state;
    const customer = {
      telefono: '0111234567',
      nome: 'ROSSI Mario',
      indirizzo: 'VIA GIALLI 26',
      cap: '10100',
      citta: 'TORINO',
      provincia: 'TO',
      note: '10 20 30',
      main_title,
      gift_title,
      gift_subtitle,
      last_message,
    };
    this.setState({ loading: true });
    try {
      const { data, ...rest } = await axios({
        method:'post',
        url: `${API_URL}/api/test-invite`,
        responseType:'blob',
        data: { ...customer }
      });
      console.log('Data', data, rest);
      const blob = new Blob([data], {type: "application/pdf"});
      const timestamp = new Date().getTime().toString();
      FileSaver.saveAs(blob, `test-${timestamp}.pdf`);
    } catch (error) {
      Swal.fire(
        'Errore',
        JSON.stringify(error),
        'error'
      )
    }
    this.setState({ loading: false });
  }
  async handleInvitesClick(event) {
    event.preventDefault();
    await this.generatePdf();
  }
  async handleLoadClick(event) {
    event.preventDefault();
    await this.addCustomers();
  }
  async generatePdf() {
    const { addresses, main_title, gift_title, gift_subtitle, last_message } = this.state;
    this.setState({ loading: true });

    try {
      const { data, ...rest } = await axios({
        method:'post',
        url: `${API_URL}/api/invites`,
        responseType:'blob',
        data: { addresses, main_title, gift_title, gift_subtitle, last_message }
      });
      console.log('Data', data, rest);
      const blob = new Blob([data], {type: "application/pdf"});
      const timestamp = new Date().getTime().toString();
      FileSaver.saveAs(blob, `inviti-${timestamp}.pdf`);
    } catch (error) {
      Swal.fire(
        'Errore',
        JSON.stringify(error),
        'error'
      )
    }
    this.setState({ loading: false });
  }
  async addCustomers() {
    const { addresses } = this.state;
    this.setState({ loading: true });
    try {
      const { data: { result, errors } } = await axios({
        method:'post',
        url: `${API_URL}/api/add-customers`,
        data: { addresses }
      });
      this.setState({ insertResult: result, insertErrors: errors });
    } catch (error) {
      Swal.fire(
        'Errore durante caricamento clienti',
        JSON.stringify(error),
        'error'
      )
    }
    this.setState({ loading: false });
  }
  async postExcel(file) {
    const formData = new FormData();
    formData.append('file', file);
    this.setState({ loading: true });
    try {
      await axios({
        method:'post',
        url: `${API_URL}/api/save-excel`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (error) {
      Swal.fire(
        'Errore durante salvataggio xls',
        JSON.stringify(error),
        'error'
      )
    }
    this.setState({ loading: false });
  }
  async handleDropFile(acceptedFiles) {
    if (acceptedFiles.length === 1) {
      console.log(acceptedFiles[0])
      const f = acceptedFiles[0];
      await this.postExcel(f);

      const reader = new FileReader();
      reader.onload = (e) => {
          const data = e.target.result;
          const wb = XLSX.read(data, {type: 'binary'});
          const addresses = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          this.setAddresses(addresses);
      };
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');

      reader.readAsBinaryString(f);
    }
  }
  render() {
    const { addresses, notValid, loading, insertResult, insertErrors } = this.state;
    const isDisabled = !addresses || addresses.length === 0 || notValid.length > 0;
    return (
      <>
        <div className="hero-head">
          <div className="container">
            <div className="columns">

              <div className="column is-3 is-offset-9">
                <button type="button" onClick={this.handleExampleClick} className="button is-large is-fullwidth is-link">
                  <span className="icon is-medium">
                    <i className="fas fa-download"/>
                  </span>
                  <span>Invito di prova</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-body has-text-centered">
          <div className="container">
            <div className="columns">
              <div className="column">
                <h4 className="title is-4">Titolo principale</h4>
                <div className="field">
                  <div className="control">
                    <textarea
                      className="textarea"
                      placeholder="APERTURA SPECIALE DOMENICA 30 SETTEMBRE CON ORARIO CONTINUATO"
                      rows={1}
                      value={this.state.main_title}
                      onChange={this.handleChangeMainTitle}
                      autoComplete="false"
                    />
                  </div>
                </div>
                <h4 className="title is-4">Titolo regalo</h4>
                <div className="field">
                  <div className="control">
                    <textarea
                      className="textarea"
                      placeholder="N 2 GUANCIALI DI NOSTRA PRODUZIONE ( VEDI FOTO RETRO )&#10;DA MERCOLEDI' 26 SETTEMBRE A DOMENICA  30 SETTEMBRE"
                      rows={2}
                      value={this.state.gift_title}
                      onChange={this.handleChangeGiftTitle}
                      autoComplete="false"
                    />
                  </div>
                </div>
                <h4 className="title is-4">Sottotitolo regalo</h4>
                <div className="field">
                  <div className="control">
                    <textarea
                      className="textarea"
                      rows={3}
                      placeholder="APERTURA NEGOZI: DAL MERCOLEDI' AL VENERDI' DALLE ORE 10.00 ALLE ORE 13.00 E DALLE ORE 15.30 ALLE ORE 19.30&#10;SABATO E DOMENICA: ORARIO CONTINUATO DALLE ORE 10.00 ALLE ORE 19.00"
                      value={this.state.gift_subtitle}
                      onChange={this.handleChangeGiftSubtitle}
                      autoComplete="false"
                    />
                  </div>
                </div>
                <h4 className="title is-4">Sottotitolo 2</h4>
                <div className="field">
                  <div className="control">
                    <textarea
                      className="textarea"
                      rows={3}
                      placeholder="VI ASPETTIAMO DA MERCOLEDI' ____________ A DOMENICA ____________"
                      value={this.state.last_message}
                      onChange={this.handleChangeLastMessage}
                      autoComplete="false"
                    />
                  </div>
                </div>
              </div>
            </div>
            {
              addresses
                ? // true
                (
                  <>
                  <h4 className="title is-4 has-text-success">Ho trovato {addresses.length - notValid.length} indirizzi validi su {addresses.length}</h4>
                    {notValid.length > 0 && (
                      <p className="has-text-danger">I record con il seguente numero di telefono non sono validi<br />{notValid.map(({ telefono }) => telefono).join(', ')}</p>
                    )}
                  </>
                )
                : // false
                (<h4 className="title is-4 has-text-danger">
                  <i className="fas fa-exclamation-triangle" />
                  {' '}
                  Prima devi caricare gli indirizzi
                  {' '}
                  <a href="/stuff/lista.xlsx" className="button is-text" >
                    Scarica lista di esempio
                  </a>
                </h4>)
            }
            {
              insertResult && (
                <>
                  <h4
                    className="title is-4 has-text-danger"
                  >
                    Errori durante l'inserimento su db ({insertErrors} errori su {addresses.length} numeri)
                  </h4>
                  <p
                    style={{ backgroundColor: 'white', maxHeight: 150, overflowY: 'auto', whiteSpace: 'pre-line' }}
                  >
                    {insertResult}
                  </p>
                </>
              )
            }
            {loading && (<Loader/>)}
          </div>
        </div>

        <div className="hero-foot">
          <div className="container">
            <div className="columns">
              <div className="column">
                <FileUploader
                  onDrop={this.handleDropFile}
                />
              </div>
              <div className="column">
                <button type="button" onClick={this.handleLoadClick} disabled={isDisabled} className="button is-large is-fullwidth is-link">
                  <span className="icon is-medium">
                    <i className="fas fa-upload"/>
                  </span>
                  <span>Carica su database</span>
                </button>
              </div>
              <div className="column">
                <button type="button" onClick={this.handleInvitesClick} disabled={isDisabled} className="button is-large is-fullwidth is-link">
                  <span className="icon is-medium">
                    <i className="fas fa-address-book"/>
                  </span>
                  <span>Genera inviti</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <div className="is-overlay is-loader" />
        )}
      </>
    );
  }
}

Home.defaultProps = {};
Home.propTypes = {};
export default compose(
  withLayout({ title: 'Home' })
)(Home);
